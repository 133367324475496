@for $i from 0 through 50 {
  .mt#{$i} {
    margin-top: #{$i}px!important;
  }
  .mb#{$i} {
    margin-bottom: #{$i}px!important;
  }
  .ml#{$i} {
    margin-left: #{$i}px!important;
  }
  .mr#{$i} {
    margin-right: #{$i}px!important;
  }
}

//@for $i from 10 through 50 {
//  .fs-#{$i} {
//    font-size: #{$i}px!important;
//  }
//}

/* float */
.float_r {
	float: right!important;
}

.float_l {
	float: left!important;
}

.float_none {
	float: none!important;
}

/* text */
.txt_r {
	text-align: right!important;
}

.txt_l {
	text-align: left!important;
}

.txt_c {
	text-align: center!important;
}

/* hover */
.on-all a {
	opacity: 1;
	@include transition(.2, opacity);
}

.on-all a:hover {
	opacity: .7;
}

.on {
	opacity: 1;
	@include transition(.2, opacity);
}

.on:hover {
	opacity: .7;
}

/* clearfix*/
.cf:before,
.cf:after {
	content:"";
	display:block;
	overflow:hidden;
}
.cf:after {
	clear:both;
}

/* pc-sp */
.switch_sp {
	display: none !important;
	@include max-screen(767px) {
		display: block !important;
		margin: 0 auto;
	}
}

.switch_pc {
	display: block !important;
	margin: 0 auto;
	@include max-screen(767px) {
		display: none !important;
	}
}